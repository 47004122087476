import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { MainButton, TransparentButton } from '../buttons';
import { AddGymModal, GymInvitedModal } from '../modals';
import { auth, gym } from '../../assets/constants';

const ButtonHeader = () => {
  const history = useHistory();
  const [addGymModalVisible, setAddGymModalVisible] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [gymInvitedModalVisible, setGymInvitedModalVisible] = useState(false);

  const onClickLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  return (
    <Row className="padding-bottom-xl">
      <Col xs={2}>
        <MainButton text={gym.add} buttonHeight="50px" float="" onClick={() => setAddGymModalVisible(true)} />
      </Col>
      <Col>
        <span className="hide-on-mobile-portrait">
          <TransparentButton text={auth.logout} buttonHeight="50px" backgroundColor="transparent" onClick={onClickLogout} />
        </span>
      </Col>
      {
        addGymModalVisible
        && (
        <AddGymModal
          handleClose={() => setAddGymModalVisible(false)}
          setGymInvitedModalVisible={setGymInvitedModalVisible}
        />
        )
      }
      {
        gymInvitedModalVisible
        && (
        <GymInvitedModal
          handleClose={() => {
            setAddGymModalVisible(false);
            setGymInvitedModalVisible(false);
          }}
        />
        )
      }
    </Row>
  );
};

ButtonHeader.propTypes = {
};

export default ButtonHeader;
