import React from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const WhiteMainButton = (props) => {
  const {
    text, buttonWidth, buttonHeight, onClick, icon, fontSize, margin, verticalAlign,
    htmlType,
  } = props;
  return (
    <Button
      type="primary"
      htmlType={htmlType}
      onClick={onClick}
      className={margin && 'margin-top-xl'}
      style={{
        float: 'right',
        fontSize,
        margin: '40px',
        border: '0px',
        borderRadius: '10px',
        width: buttonWidth,
        height: buttonHeight,
        background: 'white',
        color: 'black',
      }}
    >
      {
        icon ? (
          <Icon
            style={{ float: 'left' }}
            component={() => (
              <img
                style={{ verticalAlign }}
                src={icon}
                alt="icon"
              />
            )}
          />
        ) : ''
      }
      {text}
    </Button>
  );
};

WhiteMainButton.defaultProps = {
  onClick: () => {},
  buttonWidth: '200px',
  buttonHeight: '70px',
  icon: '',
  fontSize: '18px',
  margin: true,
  verticalAlign: '-webkit-baseline-middle',
  htmlType: '',
};

WhiteMainButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  icon: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.bool,
  verticalAlign: PropTypes.string,
  htmlType: PropTypes.string,
};

const MainButton = (props) => {
  const {
    text, buttonWidth, buttonHeight, icon, fontSize, margin, verticalAlign, onClick,
    float, loading, htmlType,
  } = props;
  return (
    <Button
      type="primary"
      loading={loading}
      htmlType={htmlType}
      onClick={onClick}
      className={margin && 'margin-top-xl'}
      style={{
        float,
        fontSize,
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: '#5663F0',
        border: '0px',
        borderRadius: '10px',
        minWidth: buttonWidth,
        height: buttonHeight,
      }}
    >
      {
        icon ? (
          <Icon
            style={{ float: 'left' }}
            component={() => (
              <img
                style={{ verticalAlign }}
                src={icon}
                alt="icon"
              />
            )}
          />
        ) : ''
      }
      {text}
    </Button>
  );
};

MainButton.defaultProps = {
  onClick: () => {},
  buttonWidth: '200px',
  buttonHeight: '70px',
  icon: '',
  fontSize: '18px',
  margin: false,
  float: 'right',
  verticalAlign: '-webkit-baseline-middle',
  loading: false,
  htmlType: null,
};

MainButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  icon: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.bool,
  float: PropTypes.string,
  verticalAlign: PropTypes.string,
  loading: PropTypes.bool,
  htmlType: PropTypes.string,
};

const TransparentButton = (props) => {
  const {
    text, buttonWidth, buttonHeight, onClick, icon, fontSize,
    margin, verticalAlign, backgroundColor,
    htmlType,
  } = props;
  return (
    <Button
      type="primary"
      htmlType={htmlType}
      onClick={onClick}
      className={margin && 'margin-top-xl'}
      style={{
        float: 'right',
        fontSize,
        color: '#5663F0',
        fontWeight: 'bold',
        backgroundColor,
        border: '1px solid #5663F0',
        borderRadius: '10px',
        minWidth: buttonWidth,
        height: buttonHeight,
      }}
    >
      {
        icon ? (
          <Icon
            style={{ float: 'left' }}
            component={() => (
              <img
                style={{ verticalAlign }}
                src={icon}
                alt="icon"
              />
            )}
          />
        ) : ''
      }
      {text}
    </Button>
  );
};

TransparentButton.defaultProps = {
  onClick: () => {},
  buttonWidth: '150px',
  buttonHeight: '70px',
  backgroundColor: '#5663F0',
  icon: '',
  fontSize: '18px',
  margin: false,
  verticalAlign: '-webkit-baseline-middle',
  htmlType: '',
};

TransparentButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  backgroundColor: PropTypes.string,
  icon: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.bool,
  verticalAlign: PropTypes.string,
  htmlType: PropTypes.string,
};

export { WhiteMainButton, MainButton, TransparentButton };
