/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import back from '../../assets/Images/back.svg';

const ContentHeader = (props) => {
  const {
    headingName, link, linkTitle, backButton, onBack,
  } = props;

  return (
    <Row className="margin-top-md margin-bottom-lg">
      {backButton && (
        <Col sm={1}>
          <span className="selectable" onClick={onBack}>
            <img className="margin-top-lg margin-left-lg" src={back} alt="backIcon" />
          </span>
        </Col>
      )}
      <Col style={{ marginLeft: backButton ? '-4em' : '0.5em' }}>
        <div style={{ fontSize: '38px', fontWeight: '500' }}>
          {headingName}
        </div>
      </Col>
      <Col>
        <Link
          to={link}
          href={link}
          style={{
            fontSize: '20px', verticalAlign: '-webkit-baseline-middle', float: 'right', color: '#5663F0', marginRight: '1em', marginTop: '0.5em',
          }}
        >
          {linkTitle}
        </Link>
      </Col>
    </Row>
  );
};

ContentHeader.propTypes = {
  link: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
  headingName: PropTypes.string.isRequired,
  backButton: PropTypes.bool,
  onBack: PropTypes.func,
};

ContentHeader.defaultProps = {
  backButton: true,
  onBack: () => {},
};

export default ContentHeader;
