import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Login, LandingPage } from './pages';
import Main from './pages/Main';

const Routes = () => ([
  <Route
    key={0}
    exact
    path="/"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <LandingPage /> : <Redirect to={{ pathname: '/main' }} />
    )}
  />,
  <Route
    key={1}
    path="/login"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <Login /> : <Redirect to={{ pathname: '/main' }} />
    )}
  />,
  <Route
    key={2}
    path="/main"
    render={() => (
      localStorage.getItem('isLoggedIn') === 'true'
        ? <Main /> : <Redirect to={{ pathname: '/login' }} />
    )}
  />,
]);

export default Routes;
