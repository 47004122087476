/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import Gyms from './Gyms';
import GymOwners from './GymOwners';

function MainComponent() {
  return (
    <>
      <Gyms fromMain />
      <GymOwners fromMain />
    </>
  );
}

export default MainComponent;
