import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($credentials: CredentialsInput!) {
    login(credentials: $credentials) {
      user {
        id
        email
        roleId
      }
      token
    }
  }
`;

export const INVITE_GYM_OWNER = gql`
  mutation inviteGymOwner($email: String!) {
    inviteGymOwner(email: $email) {
      success
    }
  }
`;

// Queries

export const GET_GYMS = gql`
  query getGyms {
    getGyms {
      id
      name
      phoneNumber
      address
      memberCount
      description
      createdAt
      logo
      owner {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
