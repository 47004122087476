/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form, Input, Modal } from 'antd';
import Text from 'antd/es/typography/Text';
import { useMutation } from '@apollo/client';
import { MainButton } from '../buttons';
import { INVITE_GYM_OWNER } from '../../graphQl/graphql';
import mailIcon from '../../assets/Images/mailIcon.svg';
import { errorMessage } from '../../utils';
import {
  forms, gym, operations, successfulMessage,
} from '../../assets/constants';

const AddGymModal = (props) => {
  const {
    handleClose, setGymInvitedModalVisible,
  } = props;
  const [inviteGymOwner] = useMutation(INVITE_GYM_OWNER);
  const [loading, setIsLoading] = useState(false);

  const onInvite = (formData) => {
    const { email } = formData;
    setIsLoading(true);
    inviteGymOwner({
      variables: {
        email,
      },
    })
      .then(() => {
        setIsLoading(false);
        setGymInvitedModalVisible(true);
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  return (
    <div>
      <Modal
        visible
        onCancel={handleClose}
        keyboard
        footer={null}
        width={900}
        bodyStyle={{ height: 'auto', padding: '10%' }}
        className="align-center"
      >
        <h1>{gym.add}</h1>
        <Form className="margin-top-xl" onFinish={onInvite}>
          <Text style={{ color: '#C7C7C7' }}>{forms.email}</Text>
          <Form.Item name="email" className="margin-left-xl margin-right-xl">
            <Input
              className="form-field"
              type="email"
            />
          </Form.Item>
          <br />
          <MainButton loading={loading} fontSize="20px" htmlType="submit" text={gym.add} float="none" />
        </Form>
      </Modal>
    </div>
  );
};

const GymInvitedModal = (props) => {
  const { handleClose } = props;

  return (
    <div>
      <Modal
        visible
        onCancel={handleClose}
        keyboard
        footer={null}
        width={900}
        bodyStyle={{ height: 'auto', padding: '10%' }}
        className="align-center"
      >
        <br />
        <h1>{successfulMessage.gymInvited}</h1>
        <img src={mailIcon} alt="mailIcon" />
        <br />
        <br />
        <h5 style={{ color: '#C7C7C7' }}>
          {successfulMessage.gymInvitedMessage}
        </h5>
        <br />
        <MainButton fontSize="20px" text={operations.okay} float="none" width="50px" onClick={handleClose} />
      </Modal>
    </div>
  );
};

export { GymInvitedModal, AddGymModal };
