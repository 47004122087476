import React from 'react';
import { Link } from 'react-router-dom';
import { WhiteMainButton } from '../components/buttons';
import main from '../assets/Images/main.svg';
import { auth } from '../assets/constants';

function LandingPage() {
  return (
    <div
      style={{
        height: '100vh',
        background: `url(${main}) no-repeat 50% 50% fixed`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Link to="/login" href="/login">
        <WhiteMainButton text={auth.login} />
      </Link>
    </div>
  );
}

export default LandingPage;
