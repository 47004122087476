/* eslint-disable react/button-has-type,react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { Row } from 'reactstrap';
import Icon, { LogoutOutlined } from '@ant-design/icons';
import gymImage from '../../assets/Images/gymImage.svg';
import gymOwners from '../../assets/Images/gymOwners.svg';
import logo from '../../assets/Images/logo.png';

const { Sider } = Layout;

function Sidebar(props) {
  const { location } = props;
  const [currentSelection, setCurrentSelection] = useState(['/main/gyms']);

  useEffect(() => {
    setCurrentSelection([location.pathname]);
  }, [location]);

  return (
    <div>
      <Sider
        className="siderStyling"
        width="100px"
        trigger={null}
      >
        {/* icon on sidebar */}
        <Row style={{ height: '13%' }}>
          <Link to="/main">
            <Icon component={() => (<img src={logo} style={{ width: '60%', padding: '30px 0px' }} alt="logo" />)} />
          </Link>
        </Row>
        <Menu
          className="sidebar-menu margin-top-sm"
          mode="inline"
          style={{ height: '100vh', listStyle: 'none', border: '0' }}
          onClick={(e) => setCurrentSelection(e.key)}
          selectedKeys={currentSelection}
        >
          {/* gym menu */}
          <Menu.Item key="/main/gyms" style={{ marginTop: '30px' }}>
            <Link to="/main/gyms" href="/main/gyms">
              <Icon component={() => (<img src={gymImage} style={{ marginBottom: '5px' }} alt="gym" />)} />
            </Link>
          </Menu.Item>

          {/* gym owners menu */}
          <Menu.Item key="/main/gym-owners" style={{ marginTop: '20px' }}>
            <Link to="/main/gym-owners" href="/main/gym-owners">
              <Icon component={() => (<img src={gymOwners} style={{ marginBottom: '5px' }} alt="gymOwners" />)} />
            </Link>
          </Menu.Item>

          {/* logout menu */}
          <Menu.Item key="logout" className="show-on-mobile-portrait" style={{ marginTop: '20px' }}>
            <Link to="/login" href="/login">
              <LogoutOutlined style={{ fontSize: '20px' }} />
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
}

export default withRouter(Sidebar);
