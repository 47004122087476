import React from 'react';
import { withRouter, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Sidebar from '../components/sidebar';
import Gyms from './Gyms';
import GymOwners from './GymOwners';
import { GymDetails, MainComponent } from './index';

const { Content } = Layout;

function Main() {
  const { styles } = Main;

  return (
    <div style={{ ...styles.layout }}>
      <Layout style={{ height: '100%', backgroundColor: '#E5E5E5' }}>
        <Sidebar />
        <Layout style={{ overflow: 'auto' }}>
          <Content style={{ padding: '3em', overflow: 'auto' }}>
            <Route
              key={0}
              exact
              path="/main"
              component={() => (
                <MainComponent />
              )}
            />
            <Route
              key={1}
              path="/main/gyms"
              component={() => (
                <Gyms />
              )}
            />
            <Route
              key={2}
              path="/main/gym-owners"
              component={() => (
                <GymOwners />
              )}
            />
            <Route
              key={2}
              path="/main/gym-details"
              component={() => (
                <GymDetails />
              )}
            />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

Main.styles = {
  layout: {
    position: 'fixed',
    top: '0px',
    bottom: '0',
    right: '0',
    left: '0',
  },
};

export default withRouter(Main);
