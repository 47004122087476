export const auth = {
  loginToContinue: 'Accedi per continuare',
  login: 'Login',
  logout: 'Disconnettersi',
};

export const forms = {
  email: 'E-mail',
  password: 'Password',
};

export const operations = {
  overview: 'Panoramica',
  viewAll: 'Mostra tutto',
  okay: 'Va bene',
};

export const successfulMessage = {
  login: 'Accesso riuscito',
  gymInvited: 'Struttura invitata',
  gymInvitedMessage: "L'utente è stato invitato e apparirà nell'elenco delle strutture una volta accettato",
};

export const gym = {
  single: 'Struttura',
  plural: 'Strutture',
  name: 'Nome della struttura',
  add: 'Aggiungi Struttura',
  member: 'Membro',
  members: 'Membri',
  location: 'Posizione',
};

export const gymOwner = {
  single: 'Proprietario della struttura',
  plural: 'Proprietari della Struttura',
  name: 'Nome del proprietario',
  joiningDate: 'Data di iscrizione',
  contact: 'Numero di contatto',
};

export const noData = 'Nessun dato';
