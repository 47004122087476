/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Input } from 'antd';
import Text from 'antd/es/typography/Text';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { MainButton } from '../components/buttons';
import loginImage from '../assets/Images/loginImage.svg';
import { LOGIN } from '../graphQl/graphql';
import { errorMessage, successMessage } from '../utils';
import { auth, forms, successfulMessage } from '../assets/constants';

function Login() {
  const history = useHistory();
  const [loginMutation] = useMutation(LOGIN);
  const [loading, setIsLoading] = useState(false);

  const onClickLogin = (formData) => {
    const { email, password } = formData;
    setIsLoading(true);
    loginMutation({
      variables: {
        credentials: {
          email,
          password,
        },
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.data.login.user.roleId === 'ADMIN') {
          localStorage.setItem(
            'user',
            JSON.stringify(res.data.login.user),
          );
          localStorage.setItem(
            'authorizationToken',
            JSON.stringify(res.data.login.token),
          );
          localStorage.setItem('isLoggedIn', 'true');
          successMessage(successfulMessage.login);
          history.push('main');
        } else {
          errorMessage('User is unauthorised to login');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  return (
    <div
      style={{
        height: '100vh',
        background: `url(${loginImage}) no-repeat 50% 50% fixed`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Row>
        <Col sm={6} />
        <Col sm={6}>
          <div style={{
            height: '100vh', background: 'white', padding: '100px', paddingTop: '25%',
          }}
          >
            <h3>
              {auth.loginToContinue}
            </h3>
            <br />
            <br />
            <Form className="margin-top-xl" onFinish={onClickLogin}>
              <Text style={{ color: '#C7C7C7' }}>{forms.email}</Text>
              <Form.Item name="email">
                <Input
                  className="form-field"
                  type="email"
                />
              </Form.Item>
              <br />
              <Text style={{ color: '#C7C7C7' }}>{forms.password}</Text>
              <Form.Item name="password">
                <Input
                  className="form-field password-field"
                  type="password"
                />
              </Form.Item>
              <br />
              <br />
              <MainButton loading={loading} fontSize="20px" htmlType="submit" text={auth.login} />
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
