/* eslint-disable react/prop-types,max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
// import gym from '../assets/Images/gym.svg';
import location from '../assets/Images/location.svg';
import ContentHeader from '../components/contentHeader';
import ButtonHeader from '../components/header/header';
import { gym, operations } from '../assets/constants';

function GymDetails() {
  const history = useHistory();
  const gymDetails = JSON.parse(localStorage.getItem('gymDetails')) || {};

  useEffect(() => () => localStorage.removeItem('gymDetails'), []);

  useEffect(() => {
    if (!Object.keys(gymDetails).length) history.push('/main');
  }, [gymDetails]);

  return (
    <div>
      <ButtonHeader />
      <ContentHeader
        backButton
        backLink=""
        headingName={gymDetails.name}
        onBack={() => {
          localStorage.removeItem('gymDetails');
          history.push('/main/gyms');
        }}
      />
      <div style={{ paddingLeft: '6.5em' }}>
        <Row>
          <Col>
            <div style={{ fontWeight: '500', fontSize: '22px', opacity: '0.6' }}>{gymDetails.extra || ''}</div>
          </Col>
        </Row>
        <Row className="margin-top-xl">
          <Col>
            <div className="margin-bottom-md margin-top-md" style={{ fontWeight: '500', fontSize: '22px' }}>{operations.overview}</div>
            <div className="margin-bottom-lg" style={{ fontWeight: '400', fontSize: '18px', opacity: '0.5' }}>
              {gymDetails.description}
            </div>
            <div className="margin-bottom-md" style={{ fontWeight: '500', fontSize: '22px', color: '#FF3A29' }}>
              <>
                {gymDetails.memberCount || 0}
                {' '}
                {gymDetails.memberCount === 1 ? gym.member : gym.members}
              </>
            </div>
            <div
              style={{ background: '#FFF5CC', display: 'flex', borderRadius: '8px' }}
              className="padding-md"
            >
              <span>
                <img
                  className="margin-right-md"
                  style={{ marginBottom: 'auto' }}
                  src={location}
                  alt="location"
                />
              </span>
              {gymDetails.address}
            </div>
          </Col>
          <Col className="align-center">
            <img style={{ width: '30em', alignSelf: 'center' }} src={gymDetails.logo} alt="gymlogo" />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default GymDetails;
