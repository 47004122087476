// eslint-disable-next-line max-len
/* eslint-disable react/prop-types,no-nested-ternary,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Col, Row, Card,
} from 'reactstrap';
import { Spin, Empty } from 'antd';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import nextIcon from '../assets/Images/nextIcon.svg';
import ContentHeader from '../components/contentHeader';
import location from '../assets/Images/location.svg';
import ButtonHeader from '../components/header/header';
import { GET_GYMS } from '../graphQl/graphql';
import { gym, noData, operations } from '../assets/constants';

function Gyms(props) {
  const { fromMain } = props;
  const { styles } = Gyms;
  const history = useHistory();

  const { loading, data } = useQuery(GET_GYMS);

  const [gyms, setGyms] = useState([]);

  useEffect(() => {
    if (!loading && data && data.getGyms) {
      setGyms(data.getGyms);
    }
  }, [loading, data]);

  const getData = () => (gyms?.length ? fromMain ? gyms.slice(0, 3) : gyms : []);

  return (
    <div>
      <ButtonHeader />
      <ContentHeader
        backButton={!fromMain}
        onBack={() => history.push('/main')}
        headingName="Gyms"
        link={fromMain && '/main/gyms'}
        linkTitle={fromMain && operations.viewAll}
      />
      {loading
        ? <div className="text-align-last-center"><Spin /></div>
        : !getData().length
          ? (
            <Col>
              <div className="align-center margin-top-md margin-bottom-xl" style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}>
                <Empty description={noData} />
              </div>
            </Col>
          )
          : (
            <Row>
              {getData().map((item) => (
                <Col sm="4">
                  <Card
                    className="shadow p-3 mb-1 product-card padding-xl"
                    style={{ borderRadius: '10px', width: '100%', height: '92%' }}
                  >
                    <Row style={{ ...styles.colStyles, borderRadius: '25px' }}>
                      <div>
                        <Row className="margin-y-lg margin-bottom-lg margin-top-md">
                          <div
                            style={{
                              background: '#FFF5CC', display: 'flex', borderRadius: '8px', fontSize: '12px', padding: '1em', alignItems: 'center',
                            }}
                          >
                            <span>
                              <img
                                className="margin-right-md"
                                src={location}
                                alt="location"
                              />
                            </span>
                            {item.address || '-'}
                          </div>
                        </Row>
                        <Row className="padding-y-lg" style={{ alignItems: 'center' }}>
                          <Col sm={1} className="margin-right-md margin-left-sm">
                            <img
                              style={{
                                verticalAlign: 'middle', height: '40px', width: '40px', borderRadius: '40px',
                              }}
                              src={item.logo}
                              alt=""
                            />
                          </Col>
                          <Col>
                            <div style={{
                              fontSize: '20px', fontWeight: '500', textAlignLast: 'center', alignSelf: 'center',
                            }}
                            >
                              {item.name || '-'}
                            </div>
                          </Col>
                          <Col>
                            <div
                              style={{
                                fontSize: '17px', fontWeight: '500', color: '#FF3A29', float: 'right',
                              }}
                              className="margin-right-sm"
                            >
                              <span>
                                {item.memberCount || 0}
                                {' '}
                                {item.memberCount === 1 ? gym.member : gym.members}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <p className="padding-lg margin-top-md margin-left-md margin-sm" style={{ color: 'grey', paddingTop: '0px' }}>
                          {item.description.length > 50 ? `${item.description.substring(0, 50)}...` : item.description || '-'}
                        </p>
                      </div>
                    </Row>
                    <Icon
                      component={() => (
                        <span
                          className="selectable"
                          onClick={() => {
                            localStorage.setItem('gymDetails', JSON.stringify(item));
                            history.push('/main/gym-details');
                          }}
                        >
                          <img
                            style={{ verticalAlign: 'initial', float: 'right' }}
                            src={nextIcon}
                            alt="icon"
                          />
                        </span>
                      )}
                    />
                  </Card>
                  <br />
                </Col>
              ))}
            </Row>
          )}
    </div>
  );
}

Gyms.styles = {
  colStyles: {
    fontSize: '15px',
    marginBottom: '10px',
  },
};

export default Gyms;
