/* eslint-disable react/prop-types,no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Avatar, List, Spin, Empty,
} from 'antd';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import ContentHeader from '../components/contentHeader';
import ButtonHeader from '../components/header/header';
import { GET_GYMS } from '../graphQl/graphql';
import {
  gym, gymOwner, noData, operations,
} from '../assets/constants';

function GymOwners(props) {
  const { fromMain } = props;
  const history = useHistory();

  const { loading, data } = useQuery(GET_GYMS);

  const [gyms, setGyms] = useState([]);

  useEffect(() => {
    if (!loading && data && data.getGyms) {
      setGyms(data.getGyms);
    }
  }, [loading, data]);

  const getData = () => (gyms?.length ? fromMain ? gyms.slice(0, 3) : gyms : []);

  return (
    <div>
      {!fromMain && <ButtonHeader />}
      <ContentHeader
        backButton={!fromMain}
        onBack={() => history.push('/main')}
        headingName={gymOwner.plural}
        link={fromMain && '/main/gym-owners'}
        linkTitle={fromMain && operations.viewAll}
      />
      {loading
        ? <div className="text-align-last-center"><Spin /></div>
        : !getData().length
          ? (
            <Col>
              <div className="align-center margin-top-lg margin-bottom-xl" style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}>
                <Empty description={noData} />
              </div>
            </Col>
          )
          : (
            <List
              itemLayout="horizontal"
              dataSource={getData()}
              className="custom-list"
              renderItem={(item) => (
                <div
                  className="item-wrapper"
                  key={item.id}
                  id={item.id}
                >
                  <List.Item style={{ display: 'flex', width: '100%' }} className="list-on-mobile">
                    <List.Item.Meta
                      className="listMeta"
                      style={{ display: 'flex', width: '100%' }}
                      avatar={(
                        <Avatar
                          size="large"
                          className="hide-on-mobile-portrait"
                          src={<img src={item.logo} alt="" />}
                        />
                      )}
                      title={(
                        <Row>
                          <Avatar
                            size="large"
                            className="show-on-mobile-portrait"
                            src={<img src={item.logo} alt="" />}
                          />
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div style={{ fontSize: '20px' }} className="margin-bottom-xs">
                              {
                                <>
                                  {item?.owner?.firstName}
                                  &nbsp;
                                  {item?.owner?.lastName}
                                </>
                                || '-'
                              }
                            </div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{gymOwner.name}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div style={{ fontSize: '20px' }} className="margin-bottom-xs">{moment(item.createdAt).format('DD, MMM YYYY') || '-'}</div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{gymOwner.joiningDate}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div style={{ fontSize: '20px' }} className="margin-bottom-xs">{item.phoneNumber || '-'}</div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{gymOwner.contact}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div style={{ fontSize: '20px' }} className="margin-bottom-xs">{item.name || '-'}</div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{gym.name}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div
                              style={{ fontSize: '20px', color: '#5663F0' }}
                              className="margin-bottom-xs"
                            >
                              {item.address || '-'}
                            </div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{gym.location}</div>
                          </Col>
                        </Row>
                    )}
                    />
                  </List.Item>
                </div>
              )}
            />
          )}
    </div>
  );
}

export default GymOwners;
